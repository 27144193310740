<template>
  <span>{{ getInvoiceNumber() }}</span>
</template>

<script>
import get from "lodash/get";
export default {
  name: "InvoicesTableNumber",
  methods: {
    getInvoiceNumber() {
      return get(this.data, "i_number");
    },
  },
};
</script>
